@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  font-family: "Brandon Grotesque";
} */

.bg-mobile-bg {
  background-image: url(../public/form-bg.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}